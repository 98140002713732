<template>
	<div class="my-account">
		<div class="header-menu va-row">
			<div class="va-row">
				<div class="flex container-ddh-menu xs12 with-bottom va-row">
					<div class="flex xs9 no-padding">
						<h1>Adoções</h1>
					</div>
					<div class="flex xs3 no-padding text-right cta-menu-area">
						<button
							id="btn-add-adoptins"
							v-on:click="showProject('post')"
							class="btn btn-change"
						>
							ADICIONAR ADOÇÃO
						</button>
					</div>
				</div>
			</div>
			<div class="clearfix"></div>
		</div>

		<div class="container-dhh va-row">
			<div class="flex md12 xs12 no-padding">
				<h4></h4>
			</div>
			<div class="flex md12 xs12 no-padding">
				<div class="ui-grid">
					<div class="tab">
						<button class="active" @click="changePage('adoptions-manager')">
							Adoções ativas
						</button>
						<button @click="changePage('adoptions-pending')">
							Adoções pendentes
						</button>
					</div>

					<div class="my-data-table va-row" id="cards">
						<div class="flex xs12 no-padding fiscal-notes">
							<div class="body-spacing body-contains">
								<!-- <div class="va-row empty-area" v-if="list.length === 0">
									<div class="empty-box">
										<div class="empty-box-header">ADOÇÕES</div>
										<div class="empty-box-body">
											<b>Nenhuma adoção cadastrada no momento.</b><br />
											As informações das adoções estarão disponível assim que as
											adoções estiverem cadastradas e ativas.
										</div>
									</div>
								</div> -->

								<div class="va-row">
									<div class="sets-list va-row">
										<div class="va-row">
											<div class="flex md3 xs12 no-padding-left">
												<div class="input-group">
													<label>Status</label>
													<select-normal
														:items="listStatus"
														:value="''"
														label="Selecione um status"
														name="typeName"
														v-model="selectStatus"
														@change="onChangeFilterStatus($event)"
													/>
												</div>
											</div>

											<div class="flex md3 xs12">
												<div class="input-group">
													<label>Escolas</label>
													<select-normal
														:items="listSchool"
														:value="''"
														label="Selecione uma escola"
														name="typeName"
														v-model="selectSchool"
														@change="onChangeFilterSchools($event)"
													/>
												</div>
											</div>

											<div class="flex md3 xs12">
												<label>Faturamento</label>
												<div class="input-group">
													<select-normal
														:items="listPaymentStatus"
														:value="''"
														label="Selecione um status"
														name="typeName"
														v-model="selectPaymentStatus"
														@change="onChangeFilterPaymentStatus($event)"
													/>
												</div>
											</div>

											<div class="flex md3 xs12 no-padding-right">
												<label>Tipo de contrato</label>
												<div class="input-group">
													<select-normal
														:items="listContracts"
														:value="''"
														label="Selecione um contrato"
														name="typeName"
														v-model="selectContracts"
														@change="onChangeFilterContracts($event)"
													/>
												</div>
											</div>
										</div>
									</div>

									<div
										class="sets-list table-list-simple va-row"
										v-if="list.length > 0"
									>
										<div class="va-row header no-padding">
											<div class="flex item main">ADOÇÕES ATIVAS</div>

											<!-- <div class="flex item">CONSULTOR</div> -->
											<div class="flex item">CONSULTOR</div>
											<div class="flex item">FATURA</div>
											<div class="flex item">MAIS INFORMAÇÕES</div>
										</div>

										<div class="flex md12 no-padding simple-list-body">
											<div
												class="flex md12 no-padding line-item"
												v-for="item in list"
												:key="item.id"
											>
												<div
													class="va-row lines"
													v-if="item.status !== 'pending'"
												>
													<div class="flex item main">
														<div class="va-row">
															<div class="flex xs12 no-padding text-left">
																<span class="product-title">
																	{{ item.name.toLowerCase() }}
																</span>
																<span class="created-data"
																	>Início: {{ item.payment }}</span
																>
																<!-- <span class="created-data date"
																	>Status: {{ item.status_label }}</span
																> -->

																<span class="created-data date"
																	>Livros contratados:

																	{{ item.books_ordered }}
																</span>

																<div
																	v-if="
																		!item.releaseDate &&
																		managerCredential.settings
																			.release_print_button &&
																		item.print_status == false
																	"
																>
																	<hr class="line-list" />

																	<span class="created-data date-text"
																		>Assim que cada professor concluir a etapa
																		de criaçao de livros, você será notificado
																		por e-mail para liberar para impressão
																	</span>

																	<button
																		type="button"
																		class="btn btn-change"
																		v-on:click="
																			openModalConfirmation('print', item)
																		"
																		v-if="
																			managerCredential.settings
																				.release_print_button &&
																			item.print_status == false
																		"
																	>
																		LIBERAR IMPRESSÃO
																	</button>

																	<!-- <button
																		type="button"
																		class="btn btn-primary cancel"
																		v-on:click="close()"
																		v-if="
																			managerCredential.settings
																				.release_print_button &&
																			item.print_status == true
																		"
																	>
																		VER CRONOGRAMA
																	</button> -->
																</div>

																<div
																	v-if="
																		item.releaseDate &&
																		managerCredential.settings
																			.release_print_button &&
																		item.print_status == false
																	"
																>
																	<hr class="line-list" />

																	<span class="created-data date-text"
																		>A impressão foi liberada com sucesso. Você
																		pode acompanhar o status da adoção no Menu
																		Cronograma.
																	</span>

																	<button
																		type="button"
																		class="btn btn-primary cancel"
																		v-on:click="
																			openModalConfirmation('print', item)
																		"
																		v-if="
																			managerCredential.settings
																				.release_print_button &&
																			item.print_status == false
																		"
																	>
																		VER CRONOGRAMA
																	</button>

																	<!-- <button
																		type="button"
																		class="btn btn-primary cancel"
																		v-on:click="close()"
																		v-if="
																			managerCredential.settings
																				.release_print_button &&
																			item.print_status == true
																		"
																	>
																		VER CRONOGRAMA
																	</button> -->
																</div>

																<!-- 
																<span class="created-data contract"
																	>Tipo de contrato:
																	{{ item.contract_method }}</span
																>
																<span
																	class="created-data print"
																	v-if="selectCnpj == ''"
																	@click="onSelectSameCnj(item.school_cnpj)"
																	>Filtrar Mesma Escola</span
																>
																<span
																	class="created-data print"
																	v-if="selectCnpj != ''"
																	@click="onSelectSameCnj('')"
																	>Limpar Filtro</span
																> -->
															</div>
														</div>
													</div>

													<div class="flex item">
														<div class="va-row">{{ item.consultant_name }}</div>
													</div>

													<div
														class="flex item"
														:class="
															managerCredential.settings.invoice_button &&
															item.payment_status == 'paid'
																? 'paid'
																: ''
														"
													>
														<div class="va-row">
															<div class="flex md12 no-padding">
																<!-- <span
																	class="created-data pay"
																	@click="openModalConfirmation('nf', item)"
																	v-if="
																		managerCredential.settings.bill_button &&
																		item.nf == null
																	"
																	>Gerar Nota Fiscal</span
																> -->
																<!-- <button
																	v-if="
																		managerCredential.settings.bill_button &&
																		item.nf
																	"
																	class="created-data pay"
																	@click="openGrade(item)"
																>
																	Visualizar Nota Fiscal
																</button> -->
																<!-- <span
																	v-if="
																		managerCredential.settings.bill_button &&
																		managerCredential.settings.invoice_button
																	"
																>
																	|
																</span> -->
																<span
																	class="paid-line"
																	v-if="
																		item.payment_status == null &&
																		item.contract_method === 'Contrato especial'
																	"
																	>Tudo certo</span
																>

																<span
																	class="created-data link"
																	@click="
																		openModalConfirmation('checkout', item)
																	"
																	v-else-if="
																		item.payment_status == null &&
																		item.contract_method !== 'Contrato especial'
																	"
																	>Gerar Pagamento</span
																>

																<span
																	class="created-data link"
																	@click="
																		openModalConfirmation(
																			'checkout_resend',
																			item
																		)
																	"
																	v-else-if="
																		item.payment_status != null &&
																		item.payment_status !== 'paid'
																	"
																	>Reenviar fatura por e-mail</span
																>
																<span
																	class="paid-line"
																	v-else-if="item.payment_status == 'paid'"
																	>Pago</span
																>
															</div>
														</div>
													</div>

													<div class="flex item action">
														<button
															class="btn btn-change"
															@click="openGrade(item)"
														>
															VER MAIS
														</button>
													</div>

													<!-- <div class="flex md2 item last-item" >
                                      <div class="va-row">
                                        <div class="flex md12 no-padding">
                                            {{item.delivery}}
                                          </div>
                                          
                                        </div>
                                    </div> -->
												</div>

												<div class="va-row pendent" v-else>
													<div class="flex md4 item">
														{{ item.name }}
													</div>
													<div class="flex md2 item dates">
														<button class="btn" v-if="!item.step_1_status">
															Produto
														</button>
														<span
															v-else
															:class="
																item.step_1_status ? 'active' : 'inactive'
															"
															>Produto</span
														>
													</div>

													<div class="flex md2 item dates">
														<button
															class="btn"
															v-if="item.step_1_status && !item.step_2_status"
															@click="openEdit(2, item.id, 'put')"
														>
															Faturamento
														</button>
														<span
															v-else
															:class="
																item.step_2_status ? 'active' : 'inactive'
															"
															>Faturamento</span
														>
													</div>

													<div class="flex md2 item dates">
														<button
															class="btn"
															v-if="
																item.step_1_status &&
																item.step_2_status &&
																!item.step_3_status
															"
															@click="openEdit(3, item.id, 'put')"
														>
															Entrega
														</button>
														<span
															v-else
															:class="
																item.step_3_status ? 'active' : 'inactive'
															"
															>Entrega</span
														>
													</div>

													<div class="flex md2">
														<button
															class="btn"
															v-if="
																item.step_1_status &&
																item.step_2_status &&
																item.step_3_status
															"
															@click="sendRelease(item)"
														>
															Liberar
														</button>
														<span
															v-else
															:class="
																item.step_1_status &&
																item.step_2_status &&
																item.step_3_status
																	? 'active'
																	: 'inactive'
															"
															>Liberado</span
														>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<vuestic-modal
									:show.sync="show"
									v-bind:large="true"
									id="adoptionEdit"
									ref="largeModal"
									:okText="'modal.confirm' | translate"
									:cancelText="'modal.cancel' | translate"
									:noButtons="true"
								>
									<div slot="title">Adicionar adoção</div>

									<adoption-edit-manager></adoption-edit-manager>
								</vuestic-modal>

								<vuestic-modal
									:show.sync="show"
									v-bind:large="true"
									ref="optionModalModal"
									:okText="'modal.confirm' | translate"
									:cancelText="'modal.cancel' | translate"
									:noButtons="true"
									:cancelDisabled="true"
								>
									<div slot="title" v-if="typeModalconfirm === 'nf'">
										Nota fiscal
									</div>
									<div
										slot="title"
										v-if="typeModalconfirm === 'checkout_resend'"
									>
										Reenviar cobrança
									</div>
									<div slot="title" v-if="typeModalconfirm === 'checkout'">
										Gerar pagamento
									</div>
									<div slot="title" v-if="typeModalconfirm === 'print'">
										Liberar livros
									</div>

									{{ confirmMessage }}

									<br /><br />
									<div class="error-label text-center">
										{{ errors.first("error-modal") }}
									</div>

									<hr />
									<br />

									<div class="va-row text-right">
										<div class="flex md12 text-right no-padding">
											<button
												class="btn btn-primary cancel"
												@click="closeModal()"
											>
												CANCELAR
											</button>

											<button
												class="btn right"
												v-if="typeModalconfirm === 'nf'"
												@click="sendNF(currentItemModal)"
											>
												CONFIRMAR
											</button>
											<button
												class="btn right"
												v-if="typeModalconfirm === 'checkout'"
												@click="sendCheckout(currentItemModal)"
											>
												CONFIRMAR
											</button>

											<button
												class="btn right"
												v-if="typeModalconfirm === 'checkout_resend'"
												@click="sendCheckout(currentItemModal)"
											>
												CONFIRMAR
											</button>

											<button
												class="btn right"
												v-if="typeModalconfirm === 'print'"
												@click="sendPrint(currentItemModal)"
											>
												CONFIRMAR
											</button>
										</div>
									</div>
								</vuestic-modal>

								<vuestic-modal
									:show.sync="show"
									v-bind:large="true"
									ref="miniModal"
									:okText="'modal.confirm' | translate"
									:cancelText="'modal.cancel' | translate"
									:noButtons="true"
									:cancelDisabled="true"
								>
									<div slot="title">Sucesso</div>

									{{ customResponse }}

									<br /><br />
									<hr />
									<br />
									<div class="va-row">
										<div class="flex md12 text-right no-padding">
											<button
												class="btn btn-white right cancel"
												@click="closeModal()"
											>
												OK, ENTENDI
											</button>
										</div>
									</div>
								</vuestic-modal>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- <infinite-loading
				v-if="filterLoaded"
				:identifier="[
					selectStatus,
					selectPayments,
					selectPaymentStatus,
					selectContracts,
					selectCnpj,
				]"
				@infinite="getProjects"
			>
				<div slot="spinner"></div>
				<div slot="no-more"></div>
				<div slot="no-results"></div>
			</infinite-loading> -->
		</div>
	</div>
</template>

<script>
	// import { required, minLength, between } from 'vuelidate/lib/validators'
	import { mask } from "vue-the-mask";
	import { mapGetters } from "vuex";
	import Vue from "vue";
	window.Vue = Vue;

	import apiErrors from "services/apiErrors";
	const axios = require("axios");

	export default {
		name: "peoples",
		directives: { mask },
		// components: { VuesticTreeCategory, VuesticTreeNode, VuesticTreeRoot },
		computed: {
			...mapGetters({
				managerCredential: "managerCredential",
				// ...
			}),
		},

		data() {
			return {
				filters: [],

				page: 1,
				list: [],

				emptyPeople: {
					name: "",
					email: "",
					role_id: 20,
					username: "",
					client_id: 1,
					products: null,
					school_id: null,
					grade_id: [],
				},

				listStatus: [],
				listPayments: [],
				listPaymentStatus: [],
				listContracts: [],

				filterLoaded: false,

				projectModalType: null,

				show: false,

				customResponse: "",

				typeModalconfirm: "",
				confirmMessage: "",
				currentItemModal: null,

				upload: false,

				listSchool: [],

				selectSchool: "",
				selectStatus: "",
				selectPayments: "",
				selectPaymentStatus: "",
				selectContracts: "",
				selectCnpj: "",
			};
		},

		mounted() {
			const self = this;

			self.getFilters();

			this.$root.$on("closeModal", () => {
				self.closeModal();
			});

			// CRUD SCHOOLS
			this.$root.$on("addProject", (project) => {
				//school.grades = []
				//self.list.push(project)
				//self.closeModal();
				//self.$router.push({ name: 'adoptions-pending' })
				//self.project_id = project.id
			});

			this.getProjects();

			this.$root.$on("editProject", (project, step) => {
				var result = self.list.filter((item) => {
					return item.id === project.id;
				});

				if (result.length > 0) {
					var index = self.list.indexOf(result[0]);

					if (step === 2) {
						self.list[index].step_2_status = true;
					} else if (step === 3) {
						self.list[index].step_3_status = true;
					}
				}
			});
		},

		methods: {
			close() {},

			dateToFormat(date) {
				var year = date.substring(8, 10);
				var parteDate = date.substring(0, 6);
				return parteDate + year;
			},

			getFilters() {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.managerCredential.token,
					},
				};

				axios
					.get(process.env.VUE_APP_ROOT_API + "/projects/filters", config)
					.then((response) => {
						self.filters = response.data.data;

						self.listStatus = response.data.data.status.map((s) => ({
							key: s.id,
							value: s.name,
						}));

						self.listPayments = response.data.data.payments.map((s) => ({
							key: s.id,
							value: s.name,
						}));

						self.listPaymentStatus = response.data.data.payment_status.map(
							(s) => ({
								key: s.id,
								value: s.name,
							})
						);

						self.listContracts = response.data.data.contracts.map((s) => ({
							key: s.id,
							value: s.name,
						}));

						self.filterLoaded = true;
					})
					.catch((e) => {
						// this.errors.push(e)
						apiErrors.handle(e);
					});
			},

			changePage(url) {
				this.$router.push({ name: url });
			},

			openModalConfirmation(type, item) {
				const self = this;

				self.currentItemModal = item;

				self.$refs.optionModalModal.open();
				self.typeModalconfirm = type;

				if (type === "nf") {
					self.confirmMessage =
						"Ao confirmar será enviado a nota fiscal para o responsável da escola: " +
						item.name;
				} else if (type === "print") {
					self.confirmMessage =
						"Você deseja liberar os livros para impressão? Lembre-se de certificar se o pagamento já foi realizado antes de confirmar o envio para produção.";
				} else if (type === "checkout_resend") {
					self.confirmMessage =
						"Ao confirmar será enviado novamente o e-mail de cobrança para o responsável da escola: " +
						item.name;
				} else if (type === "checkout") {
					self.confirmMessage =
						"Ao confirmar será enviado novamente o e-mail de cobrança para o responsável da escola: " +
						item.name;
				}
			},

			sendCheckout(item) {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.managerCredential.token,
					},
				};

				self.errors.items = [];

				axios
					.get(
						process.env.VUE_APP_ROOT_API + "/projects/send_checkout/" + item.id,
						config
					)
					.then((response) => {
						self.closeModal();
						self.customResponse = response.data.data.msg;
						self.$refs.miniModal.open();
					})
					.catch(function (error) {
						let fieldError = {
							field: "error-modal",
							msg: error.response.data.message,
							rule: "error", // optional
						};
						self.$validator.errors.add(fieldError);
					});
			},

			sendNF(item) {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.managerCredential.token,
					},
				};

				self.errors.items = [];

				axios
					.get(
						process.env.VUE_APP_ROOT_API +
							"/projects/create_invoice/" +
							item.id,
						config
					)
					.then((response) => {
						//alert("NF");
						//self.list = response.data.data.projects
						self.closeModal();
						self.customResponse = response.data.data.msg;
						self.$refs.miniModal.open();
					})
					.catch(function (error) {
						let fieldError = {
							field: "error-modal",
							msg: error.response.data.message,
							rule: "error", // optional
						};
						self.$validator.errors.add(fieldError);
					});
			},

			sendPrint(item) {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.managerCredential.token,
					},
				};

				self.errors.items = [];

				axios
					.get(
						process.env.VUE_APP_ROOT_API + "/projects/release_print/" + item.id,
						config
					)
					.then((response) => {
						self.closeModal();
						self.customResponse = response.data.data.msg;
						self.$refs.miniModal.open();
					})
					.catch(function (error) {
						let fieldError = {
							field: "error-modal",
							msg: error.response.data.message,
							rule: "error", // optional
						};
						self.$validator.errors.add(fieldError);
					});
			},

			sendRelease(item) {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + self.managerCredential.token,
					},
				};

				axios
					.post(
						process.env.VUE_APP_ROOT_API + "/projects/release",
						{ project_id: item.id },
						config
					)
					.then(function (response) {
						var result = self.list.filter((itemLista) => {
							return itemLista.id === item.id;
						});

						var index = self.list.indexOf(result[0]);
						Vue.set(self.list, index, response.data.data.project);
					})
					.catch(function (error) {
						let fieldError = {
							field: "error",
							msg: error.response.data.message,
							rule: "error", // optional
						};
						self.$validator.errors.add(fieldError);
					});
			},

			openGrade(item) {
				const self = this;

				self.$store.commit("SAVE_MANAGER_CURRENT_ADOPTION", item);
				self.$router.push({ name: "grades-manager" });
			},

			closeModal() {
				if (this.$refs.largeModal) {
					this.$refs.largeModal.close();
				}

				if (this.$refs.gradeModal) {
					this.$refs.gradeModal.close();
				}

				if (this.$refs.miniModal) {
					this.$refs.miniModal.close();
				}

				if (this.$refs.optionModalModal) {
					this.$refs.optionModalModal.close();
				}

				//this.show = false
			},

			openEdit(order, project_id, type) {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.managerCredential.token,
					},
				};

				axios
					.get(
						process.env.VUE_APP_ROOT_API + "/projects/steps/" + project_id,
						config
					)
					.then((response) => {
						var project = response.data.data.project;
						project.type = type;
						project.step = order;
						project.id = project_id;

						if (order === 2 && !project.address) {
							project.cnpj = "";
							project.address = {
								district: "",
								address_number: "",
								state: "",
								city: "",
								address1: "",
								address2: "",
								zip_postal_code: "",
							};

							project.contact_name = "";
							project.contact_email = "";
							project.contact_phone = "";
							project.contract_method_id = "";
							project.payment_method_id = "";
							project.ie = "";
							project.razao_social = "";
							project.shipping_address = {
								district: "",
								address_number: "",
								state: "",
								city: "",
								address1: "",
								address2: "",
								zip_postal_code: "",
							};
						}

						if (order === 3 && !project.shipping_address) {
							project.shipping_address = {
								district: "",
								address_number: "",
								state: "",
								city: "",
								address1: "",
								address2: "",
								zip_postal_code: "",
							};
						}

						self.$store.commit("SAVE_MANAGER_CURRENT_ADOPTION", project);
						self.project_id = project.id;
						self.$refs.largeModal.open();
					})
					.catch((e) => {
						self.errors.push(e);
					});
			},

			showProject(type) {
				const self = this;

				this.projectModalType = type;
				this.$store.commit("CLEAR_MANAGER_CURRENT_ADOPTION");

				//this.show = true
				this.$refs.largeModal.open();
			},

			onChangeFilterStatus(event) {
				this.page = 1;
				this.list = [];
				this.selectStatus = event.target.value;
				this.getProjects();
			},

			onChangeFilterPayment(event) {
				this.page = 1;
				this.list = [];
				this.selectPayments = event.target.value;
				this.getProjects();
			},

			onChangeFilterSchools(event) {
				this.page = 1;
				this.list = [];
				this.selectSchool = event.target.value;
				this.getProjects();
			},

			onChangeFilterPaymentStatus(event) {
				this.page = 1;
				this.list = [];
				this.selectPaymentStatus = event.target.value;
				this.getProjects();
			},

			onChangeFilterContracts(event) {
				this.page = 1;
				this.list = [];
				this.selectContracts = event.target.value;
				this.getProjects();
			},

			onSelectSameCnj(cnpj) {
				this.page = 1;
				this.list = [];
				this.selectCnpj = cnpj;
			},

			getProjects() {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.managerCredential.token,
					},
				};

				//let url = '/projects?page=1&limit=100&status='+ self.selectedStatus +'&payment_status='+ self.selectedPaymentStatus +'&payments='+ self.selectedPayments +'&contracts='+ self.selectedContracts;
				let url =
					"/projects?page=" +
					self.page +
					"&limit=100&status=" +
					self.selectStatus +
					"&payment_status=" +
					self.selectPaymentStatus +
					"&payments=" +
					self.selectPayments +
					"&contracts=" +
					self.selectContracts +
					"&cnpj=" +
					self.selectCnpj;

				axios
					.get(process.env.VUE_APP_ROOT_API + url, config)
					.then((response) => {
						// if (response.data.data.projects.length) {
						// 	response.data.data.projects.forEach((element) => {
						// 		self.list.push(element);
						// 	});
						// 	self.page += 1;
						// 	$state.loaded();
						// } else {
						// 	$state.complete();
						// }

						//self.list = response.data.data.projects

						if (response.data.data.projects.length) {
							response.data.data.projects.forEach((element) => {
								if (
									self.selectSchool &&
									parseInt(self.selectSchool) === parseInt(element.id)
								) {
									self.list.push(element);
								}

								if (!self.selectSchool) {
									self.list.push(element);
								}

								//if (list.length === 0) {
								var school = {
									key: element.id,
									value: element.name,
								};
								self.listSchool.push(school);
								//}
							});
							this.page += 1;
							$state.loaded();
						} else {
							//$state.complete();
						}
					})
					.catch((e) => {
						// this.errors.push(e)
						apiErrors.handle(e);
					});
			},
		},
	};
</script>
<style lang="scss" scoped>
	.error-label {
		color: red;
		text-align: left;
		margin-bottom: 10px;
	}
	.created-data {
		font-size: 12px;
		display: block;
		color: #afa2ba;

		&.date {
			margin-bottom: 0px;
		}

		&.date-text {
			margin-bottom: 8px;
		}

		&.init {
			margin-bottom: 10px;
		}

		&.nf {
			cursor: pointer;
			color: #4965bc;

			&:hover {
				text-decoration: underline;
			}
		}

		&.print {
			cursor: pointer;
			color: #4965bc;
			width: 100%;

			&:hover {
				text-decoration: underline;
			}
		}

		&.pay {
			cursor: pointer;
			color: #4965bc;
			// width: 100%;
			display: contents;

			&:hover {
				text-decoration: underline;
			}
		}
	}
	.btn-clean {
		font-family: "Roboto";
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		/* identical to box height, or 150% */
		padding: 0px;
		background: none;
		color: #000000;

		&:hover {
			text-decoration: underline;
		}
	}

	.fiscal-notes {
		border-radius: 0px 0px 16px 16px;
	}

	.simple-list-body .line-item:last-child {
		.lines {
			border-bottom: none !important;
		}
	}

	.table-list-simple {
		.item {
			&.main {
				width: 40%;
			}

			&.paid {
				color: #00cf72;
			}

			.link {
				// border-bottom: 1px solid #4965bc;
				color: #4965bc;
				text-decoration: underline;
				cursor: pointer;
				font-size: 14px;
			}

			.product-title {
				text-transform: capitalize;
			}
		}

		.lines {
			border-bottom: 1px solid #dfdae4 !important;

			// &:last-child {
			// 	border-bottom: none;
			// }

			// &:last-child {
			// 	background-color: pink !important;
			// }
			.item {
				border-right: 1px solid #dfdae4;
				padding: 24px;

				&:last-child {
					border-right: none;
				}
			}
		}
	}

	.button-actions {
		.btn {
			padding: 10px;
			border-radius: 0px;
			font-size: 10px;

			&.btn-blue {
				background-color: rgb(74, 144, 226);
				max-width: 192px;
			}
		}
	}

	.pendent {
		background-color: #fff;
		span {
			color: gray;

			&.active {
				color: green;
				font-weight: bold;
			}
		}
	}
	.btn-blue {
		// background-color:rgb(74, 144, 226);
		max-width: 192px;
	}

	.hide {
		display: none;
	}
	@media (max-width: 580px) {
		.btn-primary {
			width: 100%;
			margin-bottom: 15px;

			&:last-child {
				margin-bottom: 5px;
			}
		}
	}

	// .fiscal-notes ::v-deep .form-group:last-child {
	// 	margin-bottom: 10px !important;
	// }

	.error-label {
		color: red;
		text-align: left;
		margin-bottom: 10px;
	}
	small {
		font-family: Roboto;
		font-size: 12px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #000000;
		text-indent: 15px;
		margin-top: -5px;
		display: block;
		margin-bottom: 10px;
		// padding-left: 15px;
	}
	h5 {
		font-family: "Nunito";
		font-size: 20px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #000000;
	}
	.address-info {
		margin-bottom: 15px;
	}
	.editing {
		margin-top: 15px;
	}
	.radio-button-wrapper {
		padding: 14px 11px;
		margin-top: 10px;

		@media (max-width: 990px) {
			padding: 20px 19px;
			margin-top: 10px;
		}
	}
	.header-menu-club {
		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #68596e;
			margin-top: 50px;
			margin-bottom: 20px;

			@media (max-width: 990px) {
				font-size: 18px;
			}
		}
	}

	h4 {
		font-family: "Nunito";
		font-size: 24px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #000000;
		margin-bottom: 0;
	}
	.header-menu {
		background-color: white;

		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #68596e;
			margin-top: 34px;
			margin-bottom: 34px;

			@media (max-width: 990px) {
				font-size: 18px;
			}
		}
	}
	.my-data {
		/* Facebook bar */
		.facebook-tag {
			height: 65px;
			border-radius: 5px;
			background-color: #3a5998;
			color: white;
			padding: 14px 20px;
			margin-bottom: 20px;

			svg {
				font-size: 26px;
				margin-right: 0px;
				float: left;

				path {
					fill: #fff;
				}
			}

			.name {
				display: inline-block;
				margin-top: 1px;
				line-height: 37px;
			}

			.facebook-toggle {
				float: right;
				display: block;
				margin-top: 5px;
			}
		}
	}

	.change-password {
		border-radius: 10px;
		border: solid 2px #f0ebf5;
		background-color: #ffffff;
		padding: 24px 20px;
		position: relative;

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 7px;
			font-family: "Roboto";

			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
		}

		h5 {
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #afa2ba;
			margin-top: 0px;
			margin-bottom: 0px;
		}

		.floated-icon {
			float: left;
			width: 36px;
			height: 36px;
			font-size: 30px;

			svg {
				position: relative;
				top: -5px;
				left: -3px;

				path {
					fill: #afa2ba;
				}
			}
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 20%;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}
	}

	.my-data-table {
		h4 {
			margin-top: 50px;
			margin-bottom: 20px;
		}
	}

	.sets-list {
		label {
			font-size: 12px;
		}

		// padding: 24px;
		// padding-bottom: 0px;

		::v-deep .select {
			font-size: 14px !important;
		}
	}

	// .fiscal-notes {
	// 	border-radius: 0px 0px 10px 10px;
	// 	border: solid 1px #f0ebf5;
	// 	background-color: #ffffff;
	// 	position: relative;
	// 	border: solid 2px #f0ebf5;
	// 	border-top: 0px;
	// 	margin-bottom: 20px;

	// 	h4 {
	// 		font-size: 16px;
	// 		color: #000000;
	// 		margin-top: 0px;
	// 		margin-bottom: 10px;
	// 		font-family: "Roboto";
	// 		font-weight: bold;
	// 		font-style: normal;
	// 		font-stretch: normal;
	// 		line-height: normal;
	// 		letter-spacing: normal;
	// 	}

	// 	.body-spacing {
	// 		padding: 0px;

	// 		@media (max-width: 990px) {
	// 			padding: 25px 15px 15px 15px;
	// 		}

	// 		.btn-change-edit {
	// 			border: none;
	// 			color: #4965bc;
	// 			background: transparent;
	// 		}

	// 		//  div:last-child{
	// 		//   margin-bottom: 0px;
	// 		// }
	// 	}

	// 	// hr {
	// 	// 	border-top: solid 1px #f0ebf5;
	// 	// 	margin-top: 0px;
	// 	// 	margin-bottom: 0px;
	// 	// }

	// 	.dual-inputs {
	// 		padding: 0px 8px;
	// 		margin-bottom: 20px;
	// 	}

	// 	.new-password-group {
	// 		margin-top: 20px;
	// 		display: block;
	// 	}

	// 	.editor {
	// 		right: 18px;
	// 		top: 20%;
	// 	}

	// 	.company-code {
	// 		text-transform: capitalize;
	// 	}

	// 	::v-deep .form-group {
	// 		margin-bottom: 20px;
	// 		padding: 22px 20px;

	// 		::v-deep &:last-child {
	// 			margin-bottom: 20px;
	// 		}
	// 	}

	// 	// .panel:last-child{
	// 	//   margin-bottom: 0px;
	// 	// }
	// }

	.address-boxes {
		border-radius: 10px;
		border: solid 1px #f0ebf5;
		background-color: #ffffff;
		position: relative;
		border: solid 2px #f0ebf5;

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 10px;
			font-family: "Roboto";
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
		}

		svg {
			path {
				fill: #afa2ba;
			}
		}

		.clube {
			svg {
				path {
					fill: #fff;
				}
			}
		}

		.body-spacing {
			padding: 30px 30px;

			@media (max-width: 990px) {
				padding: 25px 15px 15px 15px;
			}
		}

		hr {
			border-top: solid 2px #f0ebf5;
		}

		.dual-inputs {
			padding: 0px 8px;
			margin-bottom: 20px;
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 20%;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}

		.more-address {
			border-radius: 10px;
			border: solid 1px #f0ebf5;
			background-color: #ffffff;
		}

		.address-row {
			padding: 16px 0px;
			position: relative;
			border-bottom: solid 1px #f0ebf5;
			b {
				font-size: 16px;
				color: #000000;
			}
			:last-child {
				border-bottom: none;
			}
		}
	}

	.address-row {
		position: relative;
	}

	.more-cards {
		.more-address {
			border-radius: 10px;
			border: solid 1px #f0ebf5;
			background-color: #ffffff;
		}
	}

	.panel {
		// height: 200px;
		border-radius: 10px;
		border: solid 2px #9378f0;
		background-color: #ffffff;
		overflow: hidden;
		margin-bottom: 20px;

		.header-panel {
			padding: 0px 20px;
			background-color: #9378f0;
			line-height: 33px;
			font-size: 16px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #ffffff;
		}

		.clube img {
			margin-top: -5px;
		}

		b {
			font-size: 16px;
			color: #000000;
		}

		.panel-body {
			position: relative;
			padding: 10px 0px;
			font-family: Roboto;
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.5;
			letter-spacing: normal;
			color: #afa2ba;
			margin: 0px !important;

			.flex.xs1 {
				-ms-flex-preferred-size: 8.33333%;
				flex-basis: 6.33333%;
				-webkit-box-flex: 0;
				-ms-flex-positive: 0;
				flex-grow: 0;
				max-width: 6.33333%;
			}

			b {
				font-family: Roboto;
				font-size: 16px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: 1.31;
				letter-spacing: normal;
				color: #000000;
			}

			.editor {
				right: 18px;
				top: 20%;
			}
		}
	}

	.subscription-box {
		// padding: 30px !important;

		font-family: Roboto;
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.31;
		letter-spacing: normal;
		color: #000000;

		.toggled {
			margin-top: 10px;
		}
	}
	.newsletter {
		// margin-top:40px;
		padding: 31px;
		border: solid 2px #f0ebf5;

		@media (max-width: 990px) {
			margin-top: 0px;
			padding: 15px;
		}
	}

	// Custom Component
	.v-switch-button {
		::v-deep &:before {
			content: "";
			display: block;
			position: relative;
			background-color: #fff;
			width: 6px;
			height: 6px;
			border-radius: 14.5px;
			margin-left: auto;
			margin-right: auto;
			top: 9px;
		}
	}

	.vue-js-switch {
		&.toggled {
			.v-switch-button {
				::v-deep &:before {
					content: "";
					display: block;
					position: relative;
					background-color: #7ed321;
					width: 6px;
					height: 6px;
					border-radius: 14.5px;
					margin-left: auto;
					margin-right: auto;
					top: 9px;
				}
			}
		}
	}

	.name-box {
		@media (max-width: 980px) {
			padding: 0px !important;
		}
	}
	.lastname-box {
		@media (max-width: 980px) {
			padding: 0px !important;
		}
	}

	// .tab {
	// 	width: 100%;
	// 	margin-bottom: -1px;
	// 	z-index: 2;
	// 	display: flex;
	// 	flex-direction: row;

	// 	button {
	// 		border: none;
	// 		padding: 30px 15px;
	// 		background: none;
	// 		border-bottom: 0px;
	// 		border-top-left-radius: 0.25rem;
	// 		border-top-right-radius: 0.25rem;
	// 		background-color: #e0dddd;

	// 		box-shadow: 0 10px 25px 0 #e2ddf6;

	// 		font-family: Nunito;
	// 		font-size: 16px;
	// 		font-weight: bold;
	// 		font-style: normal;
	// 		font-stretch: normal;
	// 		line-height: normal;
	// 		letter-spacing: normal;
	// 		text-align: center;
	// 		color: #68596e66;
	// 		outline: none;
	// 		flex: 1;

	// 		&.active {
	// 			border: solid 2px #f0ebf5;
	// 			border-bottom: 0px;
	// 			box-shadow: none;
	// 			position: relative;
	// 			z-index: 6;
	// 			background-color: #fff;
	// 			color: #68596e !important;
	// 		}

	// 		&:last-child {
	// 			float: right;
	// 		}
	// 	}
	// }

	::v-deep h3 {
		font-size: 16px !important;
		margin-top: 0px !important;
		width: 100%;
	}

	::v-deep .vuestic-modal {
		width: 50px;
		.modal-dialog {
			max-width: 720px !important;
		}
	}

	.empty-area {
		padding: 33px 31px;

		.empty-box {
			width: 100%;
			border: 1px solid #dfdae4;
			border-radius: 16px;
			overflow: hidden;

			&-header {
				padding: 22px;
				background: #f4f2fb;
				border-bottom: 1px solid #dfdae4;

				font-family: "Roboto";
				font-style: normal;
				font-weight: 700;
				font-size: 12px;
				line-height: 14px;
				/* identical to box height */

				display: flex;
				align-items: center;
				text-transform: uppercase;

				color: #68596e;
			}

			&-body {
				font-family: "Roboto";
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 21px;
				text-align: center;
				color: #68596e;
				padding: 24px;
			}
		}
	}

	.cta-menu-area {
		align-items: center;
		display: flex;
		flex-wrap: wrap-reverse;
	}

	.body-contains {
		padding: 32px !important;
	}

	.line-list {
		border-top: solid 1px #f0ebf5;
		width: 100%;
		margin: 0;
		margin-top: 8px;
		margin-bottom: 8px;
	}

	.paid-line {
		font-family: "Roboto";
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 21px;
		/* identical to box height, or 150% */

		/* color / feedback / success */

		color: #00cf72;
	}
</style>
